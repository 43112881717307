import styled from 'styled-components'

export default styled.th`
  font-weight: normal;
  .reuse-sort-icon {
    width: 16px;
    height: 16px;
    margin-left: 16px;
  }
  &:first-child {
    padding-left: 12px;
  }
  &:last-child {
    padding-right: 12px;
  }
  span,
  img {
    vertical-align: middle;
  }
  img {
    cursor: pointer;
    padding-left: 16px;
  }
`
