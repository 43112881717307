import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'

import TableHeaderCellStyled from './styled'

import SortImg from '../../../assets/images/table/sort-icon.svg'

export const TableHeaderCell = props => {
  const handleSortClicked = useCallback(() => {
    props.onSortClicked(props.sortData)
  }, [props.sortData])
  const showSortIcon = () => {
    return <img className="reuse-table-sort-btn" src={SortImg} onClick={handleSortClicked} />
  }
  return (
    <TableHeaderCellStyled className={props.className}>
      <span>{props.children}</span>
      {props.onSortClicked ? showSortIcon() : null}
    </TableHeaderCellStyled>
  )
}

TableHeaderCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
  onSortClicked: PropTypes.func,
  sortData: PropTypes.string,
}

export default memo(TableHeaderCell)
